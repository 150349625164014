<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap15PointerType"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 15: Pointer type" image-name="pointer.jpg" image-alt="Pointer type"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is a pointer?</p></li>
<li><p>What is a pointer type?</p></li>
<li><p>How to create and use variables of pointer type.</p></li>
<li><p>What is the zero value of a pointer-type variable?</p></li>
<li><p>What is dereferencing?</p></li>
<li><p>What is the specificity of slices, maps, and channels?</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Pointer</p></li>
<li><p>Memory address</p></li>
<li><p>Pointer type</p></li>
<li><p>Dereferencing</p></li>
<li><p>Referencing</p></li>
</ul>
<div id="what-is-a-pointer" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> What is a pointer? <a href="#what-is-a-pointer"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A pointer is “a data item that specifies the location of another data item”<b-link class="citation" data-cites="institute1990ieee" href="#institute1990ieee" >[@institute1990ieee]</b-link>.</p>
<p>In a program, we are constantly storing and retrieving data. For instance, strings, numbers, complex structs... At the physical level, data are stored at specific addresses in memory. Pointers contain those memory addresses.</p>
<figure>
<b-img :src="require('@/assets/images/pointer_schema.png')" alt="Pointer variable vs “classical” variable"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Pointer variable vs “classical” variable</figcaption>
</figure>
<p>Keep in mind that a pointer variable, like any other variable, also has a memory address.</p>
<div id="pointer-types" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Pointer types <a href="#pointer-types"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>There isn’t such thing as a unique pointer type. There are as many pointer types as there are types. A pointer type is the set of all pointers to variables of a given type<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>.</p>
<p>Pointer types have the following syntax :</p>
<pre v-highlightjs><code class="go" v-pre >*BaseType</code></pre>
<p>Where <span v-highlightjs><code class="go" v-pre style="display: inline">BaseType</code></span> can be any type.</p>
<p>Let’s take some examples :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">*int</code></span> denotes all pointers to variables of type <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">*uint8</code></span> denotes all pointers to variables of type <span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span>.</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >type User struct {
    ID string
    Username string
}</code></pre>
<ul>
<li><span v-highlightjs><code class="go" v-pre style="display: inline">*User</code></span> denotes all pointers to variables of type <span v-highlightjs><code class="go" v-pre style="display: inline">User</code></span></li>
</ul>
<div id="how-to-create-a-variable-of-pointer-type" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> How to create a variable of pointer type? <a href="#how-to-create-a-variable-of-pointer-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>It can be created with the following syntax :</p>
<pre v-highlightjs><code class="go" v-pre >var p *int</code></pre>
<p>Here we create a variable <span v-highlightjs><code class="go" v-pre style="display: inline">p</code></span> of type <span v-highlightjs><code class="go" v-pre style="display: inline">*int</code></span> .<span v-highlightjs><code class="go" v-pre style="display: inline">*int</code></span> is a pointer type (which base type is <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>).</p>
<p>Let’s create an integer variable named <span v-highlightjs><code class="go" v-pre style="display: inline">answer</code></span>.</p>
<pre v-highlightjs><code class="go" v-pre >var answer int = 42</code></pre>
<p>Now we can assign a value to the variable <span v-highlightjs><code class="go" v-pre style="display: inline">p</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >p = &amp;answer</code></pre>
<p>With the <span v-highlightjs><code class="go" v-pre style="display: inline">&amp;</code></span> symbol we <strong><u>get the address</u></strong> of the variable <span v-highlightjs><code class="go" v-pre style="display: inline">answer</code></span>. Let’s print this address!</p>
<pre v-highlightjs><code class="go" v-pre >fmt.Println(p)
// 0xc000012070</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">0xc000012070</code></span> is a hexadecimal number. You can spot that because it starts with <span v-highlightjs><code class="go" v-pre style="display: inline">0x</code></span>. Memory addresses are often noted in the hexadecimal format. You could still express it with the binary format (with zeros and ones), but it’s not easy to read.</p>
<div id="zero-value-of-pointer-types" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Zero value of pointer types <a href="#zero-value-of-pointer-types"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The zero value of pointer types is always <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span>. In other words, a pointer that holds no address is equal to <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span>.</p>
<div id="dereferencing" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Dereferencing <a href="#dereferencing"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A pointer variable holds an address to another variable. What if you want to retrieve the value behind the address? You can use the dereference operator <span v-highlightjs><code class="go" v-pre style="display: inline">*</code></span>.</p>
<p>Let’s take an example. We define a type struct <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >type Cart struct {
    ID   string
    Paid bool
}</code></pre>
<p>Then we create a variable <span v-highlightjs><code class="go" v-pre style="display: inline">cart</code></span> of type <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span>. We can take the address of this variable, but also follow the address:</p>
<figure>
<b-img :src="require('@/assets/images/dereferencing.png')" alt="Dereferencing/referencing"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Dereferencing/referencing</figcaption>
</figure>
<figure>
<b-img :src="require('@/assets/images/take_follow_address.png')" alt="Dereferencing/referencing"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Dereferencing/referencing</figcaption>
</figure>
<ul>
<li><p>With the operator <span v-highlightjs><code class="go" v-pre style="display: inline">*</code></span> you <strong>follow the address</strong></p></li>
<li><p>With the operator <span v-highlightjs><code class="go" v-pre style="display: inline">&amp;</code></span> you <strong>take the address</strong></p></li>
</ul>
<div id="warning-confusion-risk." class="anchor"></div>
<h2 data-number="7.1"><span class="header-section-number">7.1</span> Warning! Confusion risk. <a href="#warning-confusion-risk."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The dereference operator <span v-highlightjs><code class="go" v-pre style="display: inline">*</code></span> is the same symbol as the one used to denote a pointer type. <span v-highlightjs><code class="go" v-pre style="display: inline">*card</code></span> may denote a pointer type but also a dereferenced pointer variable. Analyze the usage context closely, and you will easily differentiate the two.</p>
<div id="nil-pointer-dereference-runtime-panic" class="anchor"></div>
<h2 data-number="7.2"><span class="header-section-number">7.2</span> Nil pointer dereference: runtime panic <a href="#nil-pointer-dereference-runtime-panic"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>There is a panic that every Go programmers have encountered :</p>
<pre v-highlightjs><code class="go" v-pre >panic: runtime error: invalid memory address or nil pointer dereference
[signal SIGSEGV: segmentation violation code=0x1 addr=0x0 pc=0x1091507]</code></pre>
<p>To better understand it, we will try to reproduce it :</p>
<pre v-highlightjs><code class="go" v-pre >// pointer/nil-pointer-dereference/main.go
package main

import &quot;fmt&quot;

func main() {
    var myPointerVar *int
    fmt.Println(*myPointerVar)
}</code></pre>
<p>In this listing, we have defined a pointer variable <span v-highlightjs><code class="go" v-pre style="display: inline">myPointerVar</code></span>. This is a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">*int</code></span> (pointers integers).</p>
<p>Then we are trying to dereference it. <span v-highlightjs><code class="go" v-pre style="display: inline">myPointerVar</code></span> variable holds a pointer that <strong>has not been initialized</strong> therefore the value of this pointer is <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span>. The program will panic because we are trying to follow a nonexistent address! We are trying to go to the nil address. The nil address is something that does not exist.</p>
<div id="maps-and-channels" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Maps and channels <a href="#maps-and-channels"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Maps and channels are already references to the internal structure. Consequently, a function/method that accepts a map or a channel can modify it, even if the parameter is not a pointer type. Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >// pointer/maps-channels/main.go
// ...

func addElement(cities map[string]string) {
    cities[&quot;France&quot;] = &quot;Paris&quot;
}</code></pre>
<ul>
<li><p>This function takes a map as input</p></li>
<li><p>It adds an entry to the map (key = “France”, value = “Paris”)</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// pointer/maps-channels/main.go
package main

import &quot;log&quot;

func main() {
    cities := make(map[string]string)
    addElement(cities)
    log.Println(cities)
}</code></pre>
<ul>
<li><p>We initialize a map named <span v-highlightjs><code class="go" v-pre style="display: inline">cities</code></span></p></li>
<li><p>Then the function <span v-highlightjs><code class="go" v-pre style="display: inline">addElement</code></span> is called</p></li>
<li><p>This program prints :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >map[France:Paris]</code></pre>
<p>We will cover more extensively channels and maps in dedicated sections.</p>
<div id="slices" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Slices <a href="#slices"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="slice-definition" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> Slice definition <a href="#slice-definition"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A slice is a collection of elements of the same type.</p>
<p>Internally a slice is a structure that has three fields :</p>
<ul>
<li><p>A length</p></li>
<li><p>A capacity</p></li>
<li><p>A pointer to an internal array.</p></li>
</ul>
<p>Here is an example slice <span v-highlightjs><code class="go" v-pre style="display: inline">EUcountries</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >package main

import &quot;log&quot;

func main() {
    EUcountries := []string{&quot;Austria&quot;, &quot;Belgium&quot;, &quot;Bulgaria&quot;}
    log.Println(EUcountries)
}</code></pre>
<div id="functionmethods-with-a-slice-as-parameterreceiver-watch-your-steps." class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> Function/methods with a slice as parameter/receiver: watch your steps. <a href="#functionmethods-with-a-slice-as-parameterreceiver-watch-your-steps."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="example-1-adding-elements-to-a-slice." class="anchor"></div>
<h4 data-number="9.2.0.1"><span class="header-section-number">9.2.0.1</span> Example 1: adding elements to a slice. <a href="#example-1-adding-elements-to-a-slice."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >// pointer/slices-add-elements/main.go
package main

import &quot;log&quot;

func main() {
    EUcountries := []string{&quot;Austria&quot;, &quot;Belgium&quot;, &quot;Bulgaria&quot;}
    addCountries(EUcountries)
    log.Println(EUcountries)
}

func addCountries(countries []string) {
    countries = append(countries, []string{&quot;Croatia&quot;, &quot;Republic of Cyprus&quot;, &quot;Czech Republic&quot;, &quot;Denmark&quot;, &quot;Estonia&quot;, &quot;Finland&quot;, &quot;France&quot;, &quot;Germany&quot;, &quot;Greece&quot;, &quot;Hungary&quot;, &quot;Ireland&quot;, &quot;Italy&quot;, &quot;Latvia&quot;, &quot;Lithuania&quot;, &quot;Luxembourg&quot;, &quot;Malta&quot;, &quot;Netherlands&quot;, &quot;Poland&quot;, &quot;Portugal&quot;, &quot;Romania&quot;, &quot;Slovakia&quot;, &quot;Slovenia&quot;, &quot;Spain&quot;, &quot;Sweden&quot;}...)
}</code></pre>
<ul>
<li><p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">addCountries</code></span> takes a slice of string as parameter</p></li>
<li><p>It modifies the slice by appending strings to it with the builtin <span v-highlightjs><code class="go" v-pre style="display: inline">append</code></span></p></li>
<li><p>It appends to the slice the missing EU countries.</p></li>
</ul>
<p><strong>Question</strong> : In your opinion, what the following program outputs?</p>
<pre v-highlightjs><code class="go" v-pre >[Austria Belgium Bulgaria Croatia Republic of Cyprus Czech Republic Denmark Estonia Finland France Germany Greece Hungary Ireland Italy Latvia Lithuania Luxembourg Malta Netherlands Poland Portugal Romania Slovakia Slovenia Spain Sweden]

[Austria Belgium Bulgaria]</code></pre>
<p><strong>Answer</strong> : This function outputs effectively :</p>
<pre v-highlightjs><code class="go" v-pre >[Austria Belgium Bulgaria]</code></pre>
<div id="explanations" class="anchor"></div>
<h4 data-number="9.2.0.2"><span class="header-section-number">9.2.0.2</span> Explanations <a href="#explanations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>The function takes an element of type <span v-highlightjs><code class="go" v-pre style="display: inline">[]string</code></span> as parameter</p></li>
<li><p>When the function is called a copy of the slice <span v-highlightjs><code class="go" v-pre style="display: inline">EUcountries</code></span> is done by the language</p></li>
<li><p>The function will get a copy of :</p>
<ul>
<li><p>the length</p></li>
<li><p>the capacity and</p></li>
<li><p>the pointer to the internal array.</p></li>
</ul></li>
<li><p>Inside the function, the countries are effectively added</p></li>
<li><p>The length of the slice will grow,</p></li>
<li><p>The runtime will allocate a new internal array.</p></li>
</ul>
<p>Let’s add a log inside the function to visualize it :</p>
<pre v-highlightjs><code class="go" v-pre >func addCountries(countries []string) {
    countries = append(countries, []string{&quot;Croatia&quot;, &quot;Republic of Cyprus&quot;, &quot;Czech Republic&quot;, &quot;Denmark&quot;, &quot;Estonia&quot;, &quot;Finland&quot;, &quot;France&quot;, &quot;Germany&quot;, &quot;Greece&quot;, &quot;Hungary&quot;, &quot;Ireland&quot;, &quot;Italy&quot;, &quot;Latvia&quot;, &quot;Lithuania&quot;, &quot;Luxembourg&quot;, &quot;Malta&quot;, &quot;Netherlands&quot;, &quot;Poland&quot;, &quot;Portugal&quot;, &quot;Romania&quot;, &quot;Slovakia&quot;, &quot;Slovenia&quot;, &quot;Spain&quot;, &quot;Sweden&quot;}...)
    log.Println(countries)
}</code></pre>
<p>This log outputs :</p>
<pre v-highlightjs><code class="go" v-pre >[Austria Belgium Bulgaria Croatia Republic of Cyprus Czech Republic Denmark Estonia Finland France Germany Greece Hungary Ireland Italy Latvia Lithuania Luxembourg Malta Netherlands Poland Portugal Romania Slovakia Slovenia Spain Sweden]</code></pre>
<ul>
<li>This change will <strong>ONLY</strong> affect the copied version</li>
</ul>
<div id="example-2-update-elements" class="anchor"></div>
<h4 data-number="9.2.0.3"><span class="header-section-number">9.2.0.3</span> Example 2: update elements <a href="#example-2-update-elements"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >// pointer/slices-update-elements/main.go
package main

import (
    &quot;log&quot;
    &quot;strings&quot;
)

func main() {
    EUcountries := []string{&quot;Austria&quot;, &quot;Belgium&quot;, &quot;Bulgaria&quot;}
    upper(EUcountries)
    log.Println(EUcountries)
}

func upper(countries []string) {
    for k, _ := range countries {
        countries[k] = strings.ToUpper(countries[k])
    }
}</code></pre>
<ul>
<li>We add a new function <span v-highlightjs><code class="go" v-pre style="display: inline">upper</code></span>, that will put in uppercase every element of a slice of strings with <span v-highlightjs><code class="go" v-pre style="display: inline">strings.ToUpper</code></span></li>
</ul>
<p><strong>Question</strong> : In your opinion, what the following program outputs?</p>
<pre v-highlightjs><code class="go" v-pre >[AUSTRIA BELGIUM BULGARIA]

[Austria Belgium Bulgaria]</code></pre>
<p><strong>Answer</strong> : This function outputs effectively :</p>
<pre v-highlightjs><code class="go" v-pre >[AUSTRIA BELGIUM BULGARIA]</code></pre>
<div id="explanations-1" class="anchor"></div>
<h4 data-number="9.2.0.4"><span class="header-section-number">9.2.0.4</span> Explanations <a href="#explanations-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>The function upper gets a copy of the slice <span v-highlightjs><code class="go" v-pre style="display: inline">EUcountries</code></span> (like before)</p></li>
<li><p>Inside the function, we change the values of the slice elements <span v-highlightjs><code class="go" v-pre style="display: inline">countries[k] = strings.ToUpper(countries[k])</code></span></p></li>
<li><p>The slice copy has still a reference to the underlying array</p></li>
<li><p>We can modify it!</p></li>
<li><p>... but only the elements of the slice that are already in the slice.</p></li>
</ul>
<div id="conclusion" class="anchor"></div>
<h4 data-number="9.2.0.5"><span class="header-section-number">9.2.0.5</span> Conclusion <a href="#conclusion"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>When you pass a slice to a function, it gets a <strong>copy</strong> of the slice.</p></li>
<li><p>It does not mean that you cannot modify the slice.</p></li>
<li><p>You can just modify the elements already present in the slice.</p></li>
</ul>
<div id="functionmethods-with-a-pointer-to-a-slice-as-parameterreceiver" class="anchor"></div>
<h2 data-number="9.3"><span class="header-section-number">9.3</span> Function/methods with a pointer to a slice as parameter/receiver <a href="#functionmethods-with-a-pointer-to-a-slice-as-parameterreceiver"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>With a pointer, you can modify the slice as expected :</p>
<pre v-highlightjs><code class="go" v-pre >// pointer/slices-add-elements-pointer/main.go
package main

import (
    &quot;log&quot;
)

func main() {
    EUcountries := []string{&quot;Austria&quot;, &quot;Belgium&quot;, &quot;Bulgaria&quot;}
    addCountries2(&amp;EUcountries)
    log.Println(EUcountries)
}

func addCountries2(countriesPtr *[]string) {
    *countriesPtr = append(*countriesPtr, []string{&quot;Croatia&quot;, &quot;Republic of Cyprus&quot;, &quot;Czech Republic&quot;, &quot;Denmark&quot;, &quot;Estonia&quot;, &quot;Finland&quot;, &quot;France&quot;, &quot;Germany&quot;, &quot;Greece&quot;, &quot;Hungary&quot;, &quot;Ireland&quot;, &quot;Italy&quot;, &quot;Latvia&quot;, &quot;Lithuania&quot;, &quot;Luxembourg&quot;, &quot;Malta&quot;, &quot;Netherlands&quot;, &quot;Poland&quot;, &quot;Portugal&quot;, &quot;Romania&quot;, &quot;Slovakia&quot;, &quot;Slovenia&quot;, &quot;Spain&quot;, &quot;Sweden&quot;}...)
}</code></pre>
<p>This program works as expected :</p>
<pre v-highlightjs><code class="go" v-pre >[Austria Belgium Bulgaria Croatia Republic of Cyprus Czech Republic Denmark Estonia Finland France Germany Greece Hungary Ireland Italy Latvia Lithuania Luxembourg Malta Netherlands Poland Portugal Romania Slovakia Slovenia Spain Sweden]</code></pre>
<ul>
<li><p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">addCountries2</code></span> takes a pointer to a slice of strings (<span v-highlightjs><code class="go" v-pre style="display: inline">*[]string</code></span>) as parameter</p></li>
<li><p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">append</code></span> is called with as first parameter <span v-highlightjs><code class="go" v-pre style="display: inline">*countriesPtr</code></span> (we dereference the pointer <span v-highlightjs><code class="go" v-pre style="display: inline">countriesPtr</code></span>)</p></li>
<li><p>The second parameter of append doesn’t change</p></li>
<li><p>The result is then affected to <span v-highlightjs><code class="go" v-pre style="display: inline">*countriesPtr</code></span></p></li>
</ul>
<div id="pointers-to-structs" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Pointers to structs <a href="#pointers-to-structs"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>There is a shortcut that allows you to directly modify a variable of type struct without the dereferencing operator :</p>
<pre v-highlightjs><code class="go" v-pre >cart := Cart{
    ID:          &quot;115552221&quot;,
    CreatedDate: time.Now(),
}
cartPtr := &amp;cart
cartPtr.Items = []Item{
    {SKU: &quot;154550&quot;, Quantity: 12},
    {SKU: &quot;DTY8755&quot;, Quantity: 1},
}
log.Println(cart.Items)
// [{154550 12} {DTY8755 1}]</code></pre>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">cart</code></span> is a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">cartPtr := &amp;cart</code></span> : takes the address of the variable cart and stores it into <span v-highlightjs><code class="go" v-pre style="display: inline">cartPtr</code></span>.</p></li>
<li><p>With the variable <span v-highlightjs><code class="go" v-pre style="display: inline">cartPtr</code></span> we can directly modify the field <span v-highlightjs><code class="go" v-pre style="display: inline">Items</code></span> of the variable <span v-highlightjs><code class="go" v-pre style="display: inline">cart</code></span></p></li>
<li><p>There is an “automatic dereference” which is easier to write than the equivalent</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >(*cartPtr).Items = []Item{
    {SKU: &quot;154550&quot;, Quantity: 12},
    {SKU: &quot;DTY8755&quot;, Quantity: 1},
}</code></pre>
<p>(which works also, but is more verbose)</p>
<div id="methods-with-pointer-receivers" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Methods with pointer receivers <a href="#methods-with-pointer-receivers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Pointers are often used as receivers of methods. Let’s take an example with a <span v-highlightjs><code class="go" v-pre style="display: inline">Cat</code></span> type:</p>
<pre v-highlightjs><code class="go" v-pre >type Cat struct {
  Color string
  Age uint8
  Name string
}</code></pre>
<p>You can define a method for this type with a pointer to a <span v-highlightjs><code class="go" v-pre style="display: inline">Cat</code></span> as receiver (<span v-highlightjs><code class="go" v-pre style="display: inline">*Cat</code></span>):</p>
<pre v-highlightjs><code class="go" v-pre >func (cat *Cat) Meow(){
  fmt.Println(&quot;Meooooow&quot;)
}</code></pre>
<p>The method <span v-highlightjs><code class="go" v-pre style="display: inline">Meow</code></span> does nothing interesting; It just prints the string <span v-highlightjs><code class="go" v-pre style="display: inline">"Meooooow"</code></span>. Here we do not modify our receiver: we do not change the value of <span v-highlightjs><code class="go" v-pre style="display: inline">Name</code></span> for instance. Here is another method that will modify one of the cat attributes:</p>
<pre v-highlightjs><code class="go" v-pre >func (cat *Cat) Rename(newName string){
  cat.Name = newName
}</code></pre>
<p>This method will change the name of the cat. Therefore, the pointer is useful because we modify one of the Cat struct fields.</p>
<p>Of course, if you do not want to use a pointer receiver, you can :</p>
<pre v-highlightjs><code class="go" v-pre >func (cat Cat) RenameV2(newName string){
  cat.Name = newName
}</code></pre>
<p>In this example, the variable <span v-highlightjs><code class="go" v-pre style="display: inline">cat</code></span> is a <strong>copy</strong>. The receiver is named “<strong>a value receiver</strong>”.As a consequence, any modifications that you will do to the cat variable will be done on the cat copy :</p>
<pre v-highlightjs><code class="go" v-pre >// pointer/methods-pointer-receivers/main.go
package main

import &quot;fmt&quot;

type Cat struct {
    Color string
    Age   uint8
    Name  string
}

func (cat *Cat) Meow() {
    fmt.Println(&quot;Meooooow&quot;)
}

func (cat *Cat) Rename(newName string) {
    cat.Name = newName
}

func (cat Cat) RenameV2(newName string) {
    cat.Name = newName
}

func main() {
    cat := Cat{Color: &quot;blue&quot;, Age: 8, Name: &quot;Milow&quot;}
    cat.Rename(&quot;Bob&quot;)
    fmt.Println(cat.Name)
    // Bob

    cat.RenameV2(&quot;Ben&quot;)
    fmt.Println(cat.Name)
    // Bob
}</code></pre>
<p>On the first line of the main function, we create a new cat. Its name is <span v-highlightjs><code class="go" v-pre style="display: inline">"Milow"</code></span>.</p>
<p>When we call the <span v-highlightjs><code class="go" v-pre style="display: inline">RenameV2</code></span> method, which has a <strong>value receiver,</strong> the name of the original cat will not change; it will stay stable.</p>
<p>When we call <span v-highlightjs><code class="go" v-pre style="display: inline">Rename</code></span>, the cat’s <span v-highlightjs><code class="go" v-pre style="display: inline">Name</code></span> field value will change.</p>
<figure>
<b-img :src="require('@/assets/images/pointer_value_reciever.png')" alt="Pointer receiver vs. value receiver"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Pointer receiver vs. value receiver</figcaption>
</figure>
<div id="when-to-use-a-pointer-receiver-when-to-use-a-value-receiver" class="anchor"></div>
<h4 data-number="11.0.0.1"><span class="header-section-number">11.0.0.1</span> When to use a pointer receiver, when to use a value receiver <a href="#when-to-use-a-pointer-receiver-when-to-use-a-value-receiver"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>Use a pointer receiver when :</p>
<ul>
<li><p>Your struct is heavy (otherwise, Go will make a copy of it)</p></li>
<li><p>You want to modify the receiver (for instance, you want to change the name field of a struct variable)</p></li>
<li><p>Your struct contains a <strong>synchronization primitive (like sync.Mutex)</strong> field. If you use a value receiver, it will also copy the mutex, making it useless and leading to synchronization errors.</p></li>
</ul></li>
<li><p>Use a value receiver</p>
<ul>
<li><p>When your struct is small</p></li>
<li><p>When you do not intend to modify the receiver</p></li>
<li><p>When the receiver is a map, a func, a chan, a slice, a string, or an interface value (because internally it’s already a pointer)</p></li>
<li><p>When your other receivers are pointers</p></li>
</ul></li>
</ul>
<div id="test-yourself" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="12.1"><span class="header-section-number">12.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>How to denote the type of a variable holding a pointer to a <span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span>?</p></li>
<li><p>What is the zero value of a pointer type?</p></li>
<li><p>What does “dereferencing” mean?</p></li>
<li><p>How to dereference a pointer?</p></li>
<li><p>Fill in the blanks. A ____ is internally a pointer to an ____.</p></li>
<li><p>True or false. When I want to modify a map in a function, my function needs to accept a pointer to the map as parameter. I also need to return the modified map.</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="12.2"><span class="header-section-number">12.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>How to denote the type of a variable holding a pointer to a <span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span> ?</p>
<ol type="1">
<li>*Product</li>
</ol></li>
<li><p>What is the zero value of a pointer type?</p>
<ol type="1">
<li>nil</li>
</ol></li>
<li><p>What does “dereferencing” mean?</p>
<ol type="1">
<li><p>A pointer is an address to a memory location where data is stored.</p></li>
<li><p>When we dereference a pointer, we can access the data stored in memory at this address.</p></li>
</ol></li>
<li><p>How to dereference a pointer?</p>
<ol type="1">
<li>By using the dereference operator <span v-highlightjs><code class="go" v-pre style="display: inline">*</code></span></li>
</ol></li>
<li><p>Fill in the blanks. A ____ is internally a pointer to an ____.</p>
<ol type="1">
<li>A <u>slice</u> is internally a pointer to an <u>array</u></li>
</ol></li>
<li><p>True or false. When I want to modify a map in a function, my function needs to accept a pointer to the map as parameter. I also need to return the modified map.</p>
<ol type="1">
<li><p>False.</p></li>
<li><p>You can just accept a map (not a pointer to a map)</p></li>
<li><p>No need also to return the modified map.</p></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Pointers are addresses to data</p></li>
<li><p>The type <span v-highlightjs><code class="go" v-pre style="display: inline">*T</code></span> denotes the set of all pointers to variables of the type <span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span>.</p></li>
<li><p>To create a pointer variable, you can use the operator <span v-highlightjs><code class="go" v-pre style="display: inline">&amp;</code></span>. It will take the address of a variable</p>
<pre v-highlightjs><code class="go" v-pre >userId := 12546584
p := &amp;userId</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">username</code></span> is a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span></p>
<ul>
<li>p is a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">*int</code></span></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">*int</code></span> denotes all the pointers to variables of type <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span></p></li>
<li><p>A function with a parameter/receiver of pointer type can modify the value that the pointer points to.</p></li>
<li><p>Maps and channels are “reference types”</p></li>
<li><p>Functions/Methods that accept maps or channels can modify the values stored internally in those two data structures (no need to pass a pointer to a map or a pointer to a channel)</p></li>
<li><p>A slice holds a reference to an array internally; any function/method that accepts a slice can modify the slice elements.</p></li>
<li><p>When you want to modify a slice length and capacity in a function, you should pass to that function a pointer to a slice (<span v-highlightjs><code class="go" v-pre style="display: inline">*[]string</code></span>)</p></li>
<li><p>Dereferencing allow you to access and modify the value stored at the pointer address.</p></li>
<li><p>To dereference a pointer, use the operator <span v-highlightjs><code class="go" v-pre style="display: inline">*</code></span></p>
<pre v-highlightjs><code class="go" v-pre >userId := 12546584
p := &amp;userId
*p = 4
log.Println(userId)</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">p</code></span> is a pointer.</p>
<ul>
<li><p>With <span v-highlightjs><code class="go" v-pre style="display: inline">*p</code></span> we dereference the pointer <span v-highlightjs><code class="go" v-pre style="display: inline">p</code></span></p></li>
<li><p>We modify the value of <span v-highlightjs><code class="go" v-pre style="display: inline">userId</code></span> with the instruction <span v-highlightjs><code class="go" v-pre style="display: inline">*p = 4</code></span></p></li>
<li><p>At the end of the code snippet, the value of <span v-highlightjs><code class="go" v-pre style="display: inline">userId</code></span> is 4 (and no longer 12546584)</p></li>
</ul></li>
<li><p>When you have a pointer to a struct, you can access a field directly with your pointer variable (without using the dereference operator)</p>
<ul>
<li>Example :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >type Cart struct {
    ID string
}
var cart Cart
cartPtr := &amp;cart</code></pre>
<ul>
<li><p>Instead of writing : <span v-highlightjs><code class="go" v-pre style="display: inline">(*cartPtr).ID = "1234"</code></span></p></li>
<li><p>You can directly write : <span v-highlightjs><code class="go" v-pre style="display: inline">cartPtr.Items = "1234"</code></span></p></li>
<li><p>The <span v-highlightjs><code class="go" v-pre style="display: inline">cart</code></span> variable is effectively modified</p></li>
</ul></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>See https://golang.org/ref/spec#Pointer_types<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap15PointerType"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap14Methods'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Methods</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap16Interfaces'}">
									<p><u><small>Next</small></u></p>
									<p><small>Interfaces</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Pointer type - Practical Go Lessons"
const description = "A pointer is “a data item that specifies the location of another data item” In a program, we are constantly storing and retrieving data. For instance, strings, numbers, complex structs... At the physical level, data are stored at specific addresses in memory. Pointers contain those memory addresses."

export default {
  name: "Chap15PointerType",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
